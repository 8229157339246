import * as finishTimer from "Store/ActionHandler/FinishTimerHandler";
import * as setHome from "Store/ActionHandler/SetHomeHandler";
import * as acceptConsent from "Store/ActionHandler/AcceptConsentHandler";

const AppReducer = (state, action) => {
  switch (action.type) {
    case "SET_BRANDING":
      return {
        ...state,
        branding: action.payload.branding,
        action: state.playing,
        round: state.round,
      };
    case "FINISH_TIMER":
      return finishTimer.handle(state, action);
    case "SET_HOME":
      return setHome.handle(state, action);
    case "SET_VIDEOS":
      return {
        ...state,
        workout: action.payload.workout,
      };
    case "ACCEPT_CONSENT":
      return acceptConsent.handle(state, action);
    default:
      throw new Error();
  }
};

export { AppReducer };
