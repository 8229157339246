import "bootstrap/dist/css/bootstrap.min.css";
import dotenv from 'dotenv';
import { App } from "Main/App";
import { AppStore } from "Store/AppContext";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';
import "./index.scss";

dotenv.config();
ReactGA.initialize(process.env.REACT_APP_GA);
ReactDOM.render(<AppStore><App /></AppStore>, document.getElementById("root"));
