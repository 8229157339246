import React, { memo, useCallback, useContext, useRef } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { AppContext } from "Store/AppContext";

import "./TimerContainer.scss";

const renderTime = (value, description, textColor) => {

  if (value === 0) return <div className={"timer-value"}>{"00:00"}</div>;
  const mins = Math.floor((value / 60) % 60);
  const secs = Math.floor(value % 60);
  const clock = `${mins < 10 ? "0" + mins : mins}:${
    secs < 10 ? "0" + secs : secs
  }`;
  return (
    <>
      <div className="timer-container-layout">
        <div className="timer-value" style={{color: textColor}}>{clock}</div>
        <div className="timer-title" style={{color: textColor}}>{description}</div>
      </div>
    </>
  );
};

export const TimerContainer = () => {
  const context = useContext(AppContext);
  const dispatch = useRef(context.dispatch);
  const timer = context.state.activityTimer;
  const isActive = context.state.activityTimer.isActive;
  const { branding } = context.state;

  const finishTimer = useCallback(() => {
    if (isActive) {
      dispatch.current({
        type: "FINISH_TIMER",
        payload: {
          origin: "activity",
        },
      });
    }
  }, [isActive]);

  const description = timer.type ? timer.type.toUpperCase() : '';
  
  return (
    <div className={getClassName(timer.isActive)}>
      <Children
        renderTime={renderTime}
        description={description}
        completeEvent={finishTimer}
        keyAttribute={getKeyAttribute(timer)}
        duration={timer.duration}
        barColor={branding.primaryColor}
        textColor={branding.textColor}
      />
    </div>
  );
};

const Children = memo(
  ({ renderTime, description, completeEvent, keyAttribute, duration, barColor, textColor }) => {
    return (
      <>
        <CountdownCircleTimer
          key={keyAttribute}
          isPlaying={true}
          size={160}
          renderTime={(value) => renderTime(value, description, textColor)}
          durationSeconds={duration}
          colors={[[barColor, 0.33]]}
          onComplete={completeEvent}
          strokeWidth={2}
        />
      </>
    );
  }
);

const getClassName = (isActive) => {
  return isActive ? "timer-layout" : "timer-layout d-none";
};

const getKeyAttribute = (timer) => {
  return timer.type === undefined ? "inactive_activity_timer" : timer.type;
};
