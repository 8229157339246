import React, { useContext, memo } from 'react';
import { AppContext } from 'Store/AppContext';
import { getWorkoutPage } from 'Domain/Service/VideoService';
import { VideoPlayer } from 'Components/Video/VideoPlayer';
import { getNextFrameByType, getPageByIndex } from 'Domain/Service/TimerService';
import './VideoBodyMobile.scss';

export const VideoBodyMobile = () => {
  const context = useContext(AppContext);
  const page = getWorkoutPage(context.state);
  const currentVideo = context.state.currentVideo;
  const textColor = context.state.branding.textColor;
  const accentColor = context.state.branding.accentColor;
  let nextExTitle = '';
  let subExSubitle = '';
  let exercises = [];

  const addEmptySpace = (array, length) => {
    let mod = length % 3;
    if(length <= 3 || mod === 0) return 0;
    const emptySpaces = 3 - mod;
    for(let i=0; i < emptySpaces; i++) {
      array.push(<div className="video-body-item" />);
    }
  }  

  if (page) {
    if (page.isPlayingAll) {
      exercises = page.exercises;
    } else {
      exercises = [page.exercises[currentVideo]];
      const nextTrainFrame = getNextFrameByType(context.state, 'train');
      if (nextTrainFrame && nextTrainFrame.type == 'train'){
        nextExTitle = 'NEXT EXERCISE';
        subExSubitle = getPageByIndex(context.state, nextTrainFrame.indexPage).exercises[nextTrainFrame.indexVideo].name;
      }  
    }
  }
  let videos = [];
  for(let index = 0; index < exercises.length; index++) {
    const exercise = exercises[index];

    let itemNumberClass = 'video-body-item';
    if (exercises.length === 1) {
      itemNumberClass = 'video-body-item-1';
    } else if(exercises.length === 2) {
      itemNumberClass = 'video-body-item-2';
    }
    
    videos.push(
      <div className={itemNumberClass} key={'v_'+index}>
        <div className="video-body-container">
          <Parameters amount={exercise.amount} unit={exercise.unit} />
          <Overlay name={exercise.name} />
          <VideoPlayer url={exercise.url} isPlaying={context.state.isPlayingVideos} />
        </div>
        <div className="next-video-information">
          <div className="video-header-round-title" style={{color: textColor}}>{nextExTitle}</div>
          <div className="video-header-round-value" style={{color: accentColor}}>{subExSubitle}</div>
        </div>
      </div>
    );
  }
  
  addEmptySpace(videos, exercises.length);
    
  return <div className="video-body-flex">{videos}</div>;
};

const Parameters = memo(({ amount, unit }) => {
  return (
    <>
      <div className="video-body-params-container">
        <div className="video-body-params">
          <div className="video-body-params-amount">{amount}</div>
          <div className="video-body-params-unit">{unit}</div>
        </div>
      </div>
    </>
  );
});

const Overlay = memo(({ name }) => {
  return (
    <div className="video-body-overlay">
      <div className="video-body-gradient">
        <div className="video-body-title-mobile">{name}</div>
      </div>
    </div>
  );
});
