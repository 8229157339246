import api from "./Setup";

export async function getCompanyTraining(companyCode, trainingCode) {
  const uri = `companies/${companyCode}/trainings/${trainingCode}`;
  try {
    return await api.get(uri);
  } catch (error) {
    return { status: 500, statusText: error };
  }
}

export async function getCompanyTheme(companyCode, themeCode) {
  const uri = `companies/${companyCode}/themes/${themeCode}`;
  try {
    return await api.get(uri);
  } catch (error) {
    return { status: 500, statusText: error };
  }
}
