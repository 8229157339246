import React, { useContext } from "react";
import CookieConsent from "react-cookie-consent";
import { AppContext } from "Store/AppContext";
import ReactGA from 'react-ga';

export const Consent = () => {
  const context = useContext(AppContext);
  const dispatch = context.dispatch;
  return (
    <CookieConsent
      onAccept={() => {
        
        ReactGA.event({
          category: 'Customer',
          action: 'Accept Consent'
        });

        dispatch({
          type: "ACCEPT_CONSENT",
          payload: {
            isCookieAccepted: true,
          },
        });
      }}
    >
      <p>
        I consent to that Aktiv TV will send me marketing materials. I also
        understand there are risks connected with my use of online content,
        including fitness videos, I voluntarily elect to engage in such
        activities knowing that it may cause hazardous to my person and
        property, and I waive any and all claims relating to such activities.
      </p>
    </CookieConsent>
  );
};
