import React, { useContext } from "react";
import { AppContext } from "Store/AppContext";
import "./ErrorMessage.scss";

export const ErrorMessage = (props) => {
    const { text } = props;
    const context = useContext(AppContext);
    const { logoUrl, textColor, backgroundColor } = context.state.branding;

    return (
        <div className="error-container" style={{ backgroundColor: backgroundColor }}>
            <div className="error-item">
                <img className="error-logo" src={ logoUrl } alt="Logo" />
            </div>
            <div className="error-item" style={{ color: textColor }}>{ text }</div>
        </div>
    );
};
