import {
  getActivityTimer,
  getCurrentFrame,
  getNextFrameIndex,
  getPauseTimer,
  getRound,
  isTrainFrame,
  isLastRound,
  isLastFrame,
  isInfiniteRounds,
} from 'Domain/Service/TimerService';

export const handle = (state, action) => {
  const newState = handleAction(state, action);
  return newState;
};

const handleAction = (state, action) => {
  return buildState(state, action);
};

const buildState = (state, action) => {
  const origin = action.payload.origin;
  const newState = { ...state };
  newState['currentFrame'] = getNextFrameIndex(state);
  newState['activityTimer'] = getActivityTimer(newState, origin);
  newState['pauseTimer'] = getPauseTimer(newState, origin);
  newState['currentRound'] = getRound(state);

  const currentFrame = getCurrentFrame(newState);
  newState['isPlayingVideos'] = isTrainFrame(currentFrame);

  if (isTrainFrame(currentFrame)) {
    newState['currentPage'] = currentFrame.indexPage;
    newState['currentVideo'] = currentFrame.indexVideo;
    newState['isPlayingVideos'] = true;
  }

  if (isLastFrame(state) && (isInfiniteRounds(state) || !isLastRound(state))) {
    newState['currentPage'] = 0;
  }

  if (isLastFrame(state) && !isInfiniteRounds(state) && isLastRound(state)) {
    newState['isPlayingVideos'] = false;
  }

  return newState;
};
