import React, { createContext, useReducer } from 'react';
import Cookies from 'js-cookie';
import { AppReducer } from './AppReducer';
import { isMobile } from "react-device-detect";

const initialState = {
  isMobile: isMobile,
  isCookieAccepted: true,
  currentFrame: 0,
  currentPage: 0,
  currentVideo: 0,
  currentRound: 0,
  isPlayingVideos: Cookies.get('CookieConsent') === 'true',
  activityTimer: {
    isActive: true,
    description: 'Chicas',
    duration: 10,
    type: 'train',
  },
  pauseTimer: {
    isActive: false,
    duration: 0,
  },
  workout: {
    name: '',
    description: '',
    notes: '',
    equipments: [],
    rounds: null, //If null, the workout is going to loop infinitely. Else the workout will loop for this value times before ending with workout complete.
    timeframes: [], //List of timeframes: type:, duration (seconds), description, amount.
    exercisesByPage: [], //List of videos: name, url, unit and amount.
  },
  branding: {
    logoUrl: 'Assets/Images/logo_1.png',
    type: 'dark',
    backgroundColor: '#000',
    textColor: '#fff',
    primaryColor: '#5ca459',
    accentColor: '#5ca459',
    overlayColor: '#1C1C1C'
  },
  action: 'start',
};

const AppStore = ({ children }) => {
  const flux = useReducer(AppReducer, initialState);
  const globalState = { state: flux[0], dispatch: flux[1] };
  return (
    <AppContext.Provider value={globalState}> {children} </AppContext.Provider>
  );
};

const AppContext = createContext(initialState);

export default AppStore;
export { AppContext, AppStore };
