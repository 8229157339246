import { TimerContainer } from 'Components/Video/TimerContainer';
import {
  getCurrentFrameDescription,
  getPagesQty,
} from 'Domain/Service/TimerService';
import React, { memo, useContext } from 'react';
import { AppContext } from 'Store/AppContext';
import './VideoHeader.scss';

export const VideoHeader = () => {
  const context = useContext(AppContext);
  const { workout, currentRound, currentPage, branding } = context.state;
  const description = getCurrentFrameDescription(context.state);
  const pageDescription = description === '' ? 'Pages' : description;
  const logoUrl = branding.logoUrl;
  const textColor = branding.textColor;
  const accentColor = branding.accentColor;
  const rounds =
    workout.rounds === 0
      ? `ROUND ${currentRound + 1} (LOOP)`
      : `ROUND ${currentRound + 1}`;
  const pagesQty = getPagesQty(context.state);

  return (
    <div className="video-header-container">
      <div className="video-header-flex-item video-header-data">
        <HeaderLogo divClass={'video-header-logo-mobile'} logoUrl={logoUrl} />
        <HeaderInfo
          workoutName={rounds}
          roundTitle={workout.name}
          currentPage={currentPage + 1}
          pageValue={pageDescription}
          totalPages={pagesQty}
          textStyles={{color: textColor}}
          accentStyles={{color: accentColor}}
        />
      </div>
      <div className="video-header-flex-item video-header-logo-container">
        <HeaderLogo divClass={'video-header-logo'} logoUrl={logoUrl} />
      </div>
      <div className="video-header-flex-item"></div>
      <div className="video-header-timer"><TimerContainer /></div>
    </div>
  );
};

const HeaderInfo = memo(
  ({ workoutName, roundTitle, currentPage, totalPages, pageValue, textStyles, accentStyles }) => {
    return (
      <div className="video-header-data-mobile">
        <div className="video-header-round">
          <div className="video-header-round-title" style={textStyles}>{workoutName}</div>
          <div className="video-header-round-value" style={accentStyles}>{roundTitle}</div>
        </div>
        <div className="video-header-page">
          <div className="video-header-page-title" style={textStyles}>{currentPage}/{totalPages}
          </div>
          <div className="video-header-page-value" style={accentStyles}>{pageValue}</div>
        </div>
      </div>
    );
  }
);

const HeaderLogo = memo(({ divClass, logoUrl }) => {
  return (
    <div className={divClass}>
      <img className="video-header-image" src={logoUrl} alt="Logo" />
    </div>
  );
});
