import {
  getActivityTimer,
  getPauseTimer,
  getCurrentFrame,
  isTrainFrame,
} from 'Domain/Service/TimerService';
import Cookies from 'js-cookie';

export const handle = (state, action) => {
  return buildState(state, action);
};

const buildState = (state, action) => {
  const isCookieAccepted = Cookies.get('CookieConsent') !== undefined;
  const timer = { isActive: false, duration: 0 };
  const newState = { ...state };
  newState['branding'] = action.payload.branding;
  newState['isCookieAccepted'] = isCookieAccepted;
  if (action.payload.workout) {
    newState['workout'] = {
      name: action.payload.workout.name,
      description: '',
      notes: '',
      equipments: action.payload.workout.equipments,
      rounds: getRounds(action.payload.workout.rounds),
      timeframes: action.payload.workout.timeframes,
      exercisesByPage: action.payload.workout.exercisesByPage,
    };
  }
  if (isCookieAccepted) {
    const currentFrame = getCurrentFrame(newState);
    newState['isPlayingVideos'] = isTrainFrame(currentFrame);
    newState['activityTimer'] = getActivityTimer(newState);
    newState['pauseTimer'] = getPauseTimer(newState, 'start');
  } else {
    newState['activityTimer'] = timer;
    newState['pauseTimer'] = timer;
    const isPreview = action.payload.playerOrigin !== undefined;
    newState['isPlayingVideos'] = isPreview
      ? action.payload.isPlayingVideos
      : false;
  }
  return newState;
};

const getRounds = (rounds) => {
  return rounds === null ? 0 : rounds;
};
