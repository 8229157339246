import { VideoBodyMobile, VideoBodyWeb, VideoHeader, VideoBodyOverlay } from "Components";
import React, { useContext } from "react";
import { AppContext } from "Store/AppContext";
import "./RoutinePlayer.scss";

const getClassName = (isActive) => {
  return isActive ? "routine-player-blur" : "";
};

export const RoutinePlayer = () => {
  const context = useContext(AppContext);
  const timer = context.state.pauseTimer;
  const { isMobile } = context.state;

  return (
    <>
      <VideoBodyOverlay />
      <div className={getClassName(timer.isActive)} >
        <VideoHeader />
        {isMobile ? <VideoBodyMobile /> : <VideoBodyWeb />}
      </div>
    </>
  );
};
