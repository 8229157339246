import {
  getCurrentFrame,
  getWorkout,
  getCurrentPage,
} from 'Domain/Service/TimerService';

export const getCurrentVideoPage = (state) => {
  const index = getCurrentFrame(state);
  const timeframes = state;
  return timeframes === undefined ? [] : timeframes[index].exercises;
};

export const getWorkoutPage = (state) => {
  const workouts = getWorkout(state);
  const page = getCurrentPage(state);
  if (workouts === undefined || page < 0) return [];
  return workouts.exercisesByPage[page];
};
