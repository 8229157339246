import React, { memo } from 'react';
import ReactPlayer from 'react-player';
import './VideoPlayer.scss';

export const VideoPlayer = (props) => {
  const url = props.url;
  if (url === '')
    return (
      <>
        <div></div>
      </>
    );
  else return <Player url={url} isPlaying={props.isPlaying} />;
};

const Player = memo(({ url, isPlaying }) => {
  return (
    <div className="video-player-wrapper">
      <ReactPlayer
        className="video-player"
        url={url}
        volume={0}
        muted={true}
        playing={isPlaying}
        playsinline
        loop={true}
        width={'100%'}
        height={'100%'}
        onError={error => console.log(error)}
      />
    </div>
  );
});
