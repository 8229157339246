import {
  getActivityTimer,
  getPauseTimer,
  getCurrentFrame,
  isTrainFrame,
} from 'Domain/Service/TimerService';

export const handle = (state, action) => {
  const isCookieAccepted = action.payload.isCookieAccepted;
  const newState = { ...state };
  newState['isCookieAccepted'] = isCookieAccepted;
  const currentFrame = getCurrentFrame(newState);
  newState['isPlayingVideos'] = isTrainFrame(currentFrame);
  newState['activityTimer'] = getActivityTimer(newState);
  newState['pauseTimer'] = getPauseTimer(newState);
  return newState;
};
